// Notifications
.notifications-wrapper {
  position: absolute;
  z-index: 1200;
  max-height: 58rem;
  overflow: auto;
  right: 10rem;
  top: 8rem;
  width: 29.75rem;
  background: $c-white;
  border-radius: 0px 0px 1.25rem 1.25rem;
  @extend .md-text;

  height: 0;
  opacity: 0;
  transition: all 0.5s;

  &.active {
    height: auto;
    opacity: 1;
  }

  .notification {
    padding: 1.5rem 1.25rem;
    border-bottom: 1px solid $c-gray-80;
    color: $c-gray-30;

    &:last-child {
      border-bottom: none;
    }

    &-row {
      display: flex;
      align-items: center;
      margin-bottom: 1rem;

      &:last-child {
        margin-bottom: 0;
      }
    }
    &__icon {
      width: 3.375rem;
      height: 3.375rem;
      margin-right: 0.625rem;
    }
    &__from {
      @extend .md-semibold-text;
      white-space: nowrap;
      max-width: 21rem;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    svg {
      cursor: pointer;
    }
    &__geo {
      margin-left: auto;
      width: 2rem;
      height: 2rem;
    }
    &__message {
      @extend .sm-text;
    }
    &__date {
      @extend .caption-text;
      color: $c-gray-70;
      text-align: right;
    }

    &.no-item {
    }
  }
}
