.selection-wrapper {
  position: absolute;
  top: 16rem;
  left: 0;
  height: calc(100vh - 16rem);
  max-height: calc(100vh - 16rem);
  @extend .custom-scroll;
  box-sizing: border-box;
  width: 44.5rem;
  background: $c-white;
  box-shadow: $shadow;
  padding-bottom: 0;
  z-index: 1100;

  left: -44.5rem;
  transition: all 0.3s ease-in-out;

  &.active {
    left: 0;
  }

  .checkbox-wrapper {
    span {
      color: $c-gray-30;

      b {
        width: 6rem;
        margin-right: 1.5rem;
        display: inline-block;
        color: $c-gray-60;
        font-weight: 400;
      }
    }
    span.header-checkbox {
      color: $c-gray-60;
    }
  }
}
.modal-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(55, 55, 55, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1100;

  .modal-card {
    background: $c-white;
    box-shadow: $shadow;
    border-radius: 1.25rem;
    text-align: center;
    width: 75rem;
    padding: 3.5rem 0 5rem;
    color: $c-gray-30;

    &__title {
      @extend .h3-text;
      margin-bottom: 3.5rem;
    }
    &__message {
      @extend .md-text;
      margin-bottom: 3.5rem;
    }
  }
}

.accept-tab {
  .filter-list {
    margin: 0 -3.5rem;
    .filter-list__title {
      max-width: unset;
    }

    .datepicker-wrapper {
      margin-left: auto;
    }
    .DatePicker__calendarContainer {
      transform: translateX(-90%);
    }
  }
  .filter__line {
    margin: 3rem -3.5rem 3rem;
  }
  .rate-icon {
    margin: 0 0.25rem 0 auto;
  }
  .user-links {
    display: flex;
    align-items: center;
    .icon-wrapper {
      margin-left: 0.75rem;
    }
  }
}
