.map-wrapper {
  position: relative;
  height: calc(100vh - 16rem);
}
.marker-wrapper.full-card {
  position: static;
}

.zoom-controll {
  position: absolute;
  bottom: 5rem;
  right: 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: $c-gray-30;
  border-radius: 3rem;
  width: 3.5rem;
  user-select: none;

  &__item {
    cursor: pointer;
    margin: 1.5rem 0;
    color: $c-gray-80;
    position: relative;
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    transition: all 0.3s ease-in;

    span {
      display: block;
      width: 1.5rem;
      height: 0.25rem;
      background: $c-white;
      border-radius: 1rem;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      transition: all 0.3s ease-in;

      &:nth-child(2) {
        height: 1.5rem;
        width: 0.25rem;
      }
    }

    &:active {
      background: $c-gray-80;
      span {
        background: $c-gray-30;
        // transform: scale(1.05) translate(-50%, -50%);
      }
    }
  }
}
.map-loader {
  .loading-wrapper {
    z-index: 9999;
    top: 16rem;
  }
}
