.filter {
  display: flex;
  align-items: center;
  height: 8rem;
  padding-right: 4rem;
  position: relative;
  z-index: 1005;

  .filter__left {
    display: flex;
    padding: 0 3rem 0 3.75rem;

    .btn-icon:first-child {
      margin-right: 1.75rem;
    }
  }
  .label {
    font-size: $fs-p3;
    line-height: $fs-p3-line;
    color: $c-gray-70;
    margin-right: 1.25rem;
    white-space: nowrap;
  }
  .switch {
    margin-right: 3rem;
  }
  .select-wrapper {
    // align-self: flex-start;

    .select__inner {
      width: 30rem;
    }
  }

  .address-input-wrapper {
    position: relative;
    margin: 0 auto 0 0;
    padding: 0 6.75rem;
    border-left: 1px solid #a6acbe33;
    .search-input__logo {
      position: absolute;
      left: -3.8rem;
      top: 50%;
      transform: translateY(-50%);
      height: 3rem;
      cursor: pointer;

      img {
        width: 3rem;
      }
    }
    .select {
      margin-left: 0;
    }
    .select__inner {
      width: 40rem;
      border: none;
    }
  }
}
.filter-card {
  position: relative;
  box-sizing: border-box;
  width: 44.5rem;
  height: calc(100vh - 16rem);
  max-height: calc(100vh - 16rem);
  background: $c-white;
  box-shadow: $shadow;
  position: absolute;
  top: 16rem;
  left: -44.5rem;
  transition: all 0.3s ease-in-out;
  z-index: 1000;

  &.active {
    left: 0;
  }

  .filter-card__no-items {
    padding: 1.5rem 0;
    color: $c-gray-70;
    text-align: center;
    @extend .md-semibold-text;
  }
  .filter-card__inner {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 28rem);
  }
}
.filter-list {
  font-weight: 300;
  .filter-list__item {
    padding: 1.5rem 2rem 1.5rem 3.5rem;
    border-bottom: 1px solid $c-gray-95;
    @extend .caption-text;

    &-top {
      display: flex;
      align-items: flex-start;
      margin-bottom: 1.5rem;
    }

    &:last-child {
      border-bottom: none;
      // padding-bottom: 0;
    }
  }
  .filter-list__item__btn {
    width: 1.5rem;
    margin-left: auto;
    cursor: pointer;
    transition: all 0.3s ease-in;

    &:hover {
      transform: scale(1.1);
    }

    &:last-child {
      margin-left: 1rem;
    }
  }

  &:last-child {
    border-bottom: 0;
  }
  .filter-list__avatar {
    width: 5.75rem;
    height: 5.75rem;
    border-radius: 50%;
    margin-right: 1.5rem;
  }
  .filter-list__title {
    overflow: hidden;
    text-overflow: ellipsis;
    max-height: 4.5rem;
    max-width: 14rem;
    font-weight: 600;
    color: $c-gray-30;
    // width: 13rem;
    @extend .sm-text;
    margin-bottom: 0.25rem;
  }
  .filter-list__price {
    color: $c-gray-60;
    display: flex;
    align-items: center;
    @extend .caption-text;
    svg {
      margin-right: 0.5rem;
    }
  }
  .filter-list__bottom {
    display: flex;
    align-items: center;

    .btn.btn-primary {
      @extend .sm-text;
      margin: 3rem 1.75rem 3rem auto;
    }
  }
}
.filter-menu {
  padding: 3rem 3.5rem;

  .filter-menu-header {
    box-shadow: 0 0 0 1px rgba(95, 100, 117, 0.4);
    border-radius: 1rem;
    display: flex;
    align-items: center;
    overflow: hidden;
    margin: 0 0 2.5rem 0;
    width: 100%;
    box-sizing: border-box;

    &__item {
      height: 100%;
      @extend .sm-text;
      flex-grow: 1;
      padding: 1rem 0;
      text-align: center;
      cursor: pointer;
      background: $c-white;

      &.active {
        color: $c-white;
        background: $c-primary-gradient;
      }
    }
  }
  .filter-list__bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    justify-items: flex-end;
    .btn.btn-primary {
      margin: 3rem 0 3rem auto;
    }
  }
  .filter-menu__title {
    @extend .h4-text;
    color: $c-gray-30;
    margin-bottom: 3rem;
  }
  .search-input {
    border: 1px solid $c-gray-70;
    border-radius: 1.25rem;
    padding: 1.5rem 2rem 1.5rem 6rem;
    margin-bottom: 2.5rem;
  }
  .filter__line {
    border-top: 1px solid $c-gray-80;
  }
  .checkbox-wrapper {
    color: $c-gray-30;
    font-size: $fs-p3;
    line-height: $fs-p3-line;
    text-decoration: none;
    font-weight: normal;
    margin-bottom: 0;
    width: 85%;
    height: auto;
    @extend .sm-text;

    label {
      width: auto;
    }
  }
  .filter-checkbox-row {
    display: flex;
    align-items: center;
    margin: 1.5rem 0;
    @extend .sm-text;

    b {
      @extend .sm-semibold-text;
    }

    .filter-list__item__btn {
      margin: 0.25rem 0 0 1rem;
      width: 2rem;
    }
    .result {
      display: flex;
      margin-left: auto;
      color: $c-gray-30;

      @extend .sm-semibold-text;
    }
    .checkbox-wrapper .result {
      margin-left: 0.75rem;
    }
    .filter-checkbox-row__label {
      margin-left: auto;
      width: 3rem;
      display: flex;
      justify-content: center;
      align-items: center;
      svg {
        width: 1.5rem;
        height: 1.75rem;
      }
    }
    &.top-checkbox {
      margin-top: 0;
      align-items: center;
      .checkbox-wrapper,
      .result {
        color: $c-gray-60;
      }
    }
  }
  .select-wrapper {
    width: 100%;
    margin-bottom: 1.25rem;

    .select__inner {
      width: 100%;
    }
  }
}
.range-label {
  display: flex;
  align-items: center;
  margin-bottom: 1.25rem;
  @extend .sm-text;

  &__value {
    margin-left: auto;
    @extend .sm-text;
    font-weight: 600;
    color: $c-primary;
  }
}
.rc-slider {
  margin: 0 1.1rem 3rem;
  width: auto !important;
  &-track {
    background-color: none;
    background: $c-primary-gradient;
  }
  &-rail {
    background-color: #e4e6ea !important;
  }
  &-handle {
    border: none !important;
    background-color: #fff !important;
    width: 2rem !important;
    height: 2rem !important;
    top: 50%;
    transform: translate(-50%, -25%) !important;
    box-shadow: 0px 0.125rem 0.375rem rgba(80, 86, 113, 0.3) !important;
  }
}
.search-input {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  padding: 0 6.75rem;
  margin-right: auto;
  border-left: 1px solid #a6acbe33;

  input {
    border: none;
    transition: all 0.3s;
    font-size: $fs-p3;
    line-height: $fs-p3-line;

    &::placeholder {
      color: $c-gray-70;
    }
    &:focus,
    &:active {
      color: $c-gray-30;
      border: none;
      outline: none;
    }
  }
  .search-input__logo {
    position: absolute;
    left: 2.5rem;
    top: 50%;
    transform: translateY(-50%);
    height: 3rem;
    cursor: pointer;

    img {
      width: 3rem;
    }
  }
}
