.analytics {
  padding: 0 3.5rem 3rem;
  overflow: auto;
  &-tabs {
    height: 100%;
    display: flex;
    flex-direction: column;

    &-header {
      display: flex;
      justify-content: center;
      &__item {
        margin: 0 2rem;
        padding: 2rem 0 2rem;
        position: relative;
        color: $c-gray-70;
        cursor: pointer;
        @extend .md-text;

        &.active {
          color: $c-primary;

          &::before {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            background: $c-primary-gradient;
            height: 0.5rem;
            border-radius: 1.25rem 1.25rem 0 0;
          }
        }
      }
    }
    &-body {
      @extend .card-default;
      position: relative;
      color: $c-gray-30;
      flex-grow: 1;
    }
    .table .t-header__item:nth-child(1),
    .table .t-row__item:nth-child(1) {
      width: 30rem;
      padding-right: 2rem;
    }

    .tab-title {
      @extend .h3-text;

      height: 10rem;
      display: flex;
      align-items: center;
      padding: 0 3rem;
      border-bottom: 1px solid $c-gray-80;
      margin-bottom: 3rem;

      .btn-label {
        margin-right: 3rem;
      }
    }
    .tab-subtitle {
      @extend .h4-semibold-text;

      padding: 0 3rem;
      margin-bottom: 3rem;
    }
  }

  .graphic-charts {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    .chart-card {
      width: calc((100% - 10rem) / 3);
      margin-bottom: 3rem;
      margin-right: 5rem;
      &:nth-child(3n) {
        margin-right: 0;
      }
    }
  }

  .layout {
    padding: 0 6rem;

    &.pb {
      padding-bottom: 3rem;
    }
  }
}

.chart-card {
  @extend .card-default;
  cursor: pointer;
  // width: 395px;

  &__title {
    height: 9rem;
    display: flex;
    align-items: center;
    padding-left: 3rem;
    border-bottom: 1px solid $c-gray-80;
    @extend .lg-text;
  }

  &-content {
    display: flex;
    flex-direction: column;
    padding: 3rem 2.5rem 4rem;
  }

  &__row {
    display: flex;
    align-items: space-between;
    @extend .md-text;

    b {
      font-weight: 600;
    }
  }

  .diagram {
    position: relative;
    width: 22.625rem;
    height: 22.625rem;
    margin: auto;
    .circle-bg {
      fill: none;
      stroke: $c-gray-80;
      stroke-width: 2.8;
    }

    .circle {
      fill: none;
      stroke-width: 2.8;
      stroke-linecap: round;
      // animation: progress 1s ease-out forwards;
      transition: all 1s;
    }

    // @keyframes progress {
    //   0% {
    //     stroke-dasharray: 0 100;
    //   }
    //   100% {
    //     stroke-dasharray: 0 100;
    //   }
    // }

    .percentage {
      fill: $c-gray-60;
      font-size: 6.5px;
      text-anchor: middle;
      font-weight: normal;
    }
  }

  &.min {
    .diagram {
      height: auto;
      width: 15rem;
      margin: 0;
      margin-right: 4rem;
    }
    .chart-card-content {
      flex-direction: row;
      align-items: center;
    }
    .chart-card__info {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: calc(100% - 15rem);
    }
  }
}
.date-table {
  @extend .card-default;

  &-header {
    padding: 1rem 2rem 1rem 3rem;
    display: flex;
    align-items: center;

    @extend .lg-text;
  }

  &-controll {
    display: flex;
    align-items: center;
    margin-left: auto;

    &__date {
      color: $c-gray-70;
      padding: 0 2rem;
    }
  }

  &-body {
  }
  &-row {
    display: flex;
    flex-wrap: wrap;

    &:nth-child(odd) {
      background: $c-gray-95;
      color: $c-gray-70;

      .date-table-cell {
        font-weight: 400;
      }
    }
  }
  &-cell {
    width: calc(100% / 19);
    height: 6rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    @extend .sm-text;

    &.cell-result {
      width: 11rem;
      border-left: 1px solid $c-gray-80;

      &:first-child {
        margin-left: auto;
      }
    }
  }
}
.table {
  // position: relative;
  .t-header {
    height: 6.25rem;
    padding: 0 3rem 0 6rem;
    background: $c-primary;
    border-radius: 1.25rem;
    display: flex;
    align-items: center;
    color: $c-white;

    @extend .caption-semibold-text;

    &__item {
      &:nth-child(1) {
        width: 22.5rem;
      }
      &:nth-child(2) {
        width: 15rem;
      }
      &:nth-child(3) {
        width: 15rem;
      }
      &:nth-child(4) {
        width: 17.2rem;
      }
      &:nth-child(5) {
        width: 15.5rem;
      }
      &:nth-child(6) {
        width: 18.5rem;
      }
      &:nth-child(7) {
        width: 22.5rem;
      }
      &:nth-child(8) {
        width: 21.5rem;
      }
    }
  }
  .t-row {
    @extend .sm-semibold-text;
    color: $c-gray-60;
    padding: 0 3rem 0 6rem;
    display: flex;
    align-items: center;
    height: 6.25rem;

    &__title {
      .checkbox-wrapper {
        line-height: 1.5rem;
        height: 3.1rem;
        overflow: hidden;
        // white-space: nowrap;
        text-overflow: ellipsis;
        width: 100%;
        display: block;
      }
    }
    &__item {
      align-items: center;
      span {
        cursor: pointer;
        transition: all 0.3s;
        &:hover {
          color: $c-gray-20;
        }
      }
      &:nth-child(1) {
        width: 22.5rem;
        position: relative;
        .t-row__collapse-btn {
          height: 1.5rem;
          width: 1.5rem;
          background: none;
          position: absolute;
          left: -3rem;
          top: 50%;
          transform: translateY(-50%);
          padding: 0;
          border: 0;
          cursor: pointer;

          img {
            width: 100%;
          }
        }
      }
      &:nth-child(2) {
        width: 15rem;
      }
      &:nth-child(3) {
        width: 15rem;
      }
      &:nth-child(4) {
        width: 17.2rem;
      }
      &:nth-child(5) {
        width: 15.5rem;
      }
      &:nth-child(6) {
        width: 18.5rem;
      }
      &:nth-child(7) {
        width: 22.5rem;
      }
      &:nth-child(8) {
        width: 21.5rem;
      }
    }

    .count {
      color: $c-primary;
      font-weight: bold;
    }
  }
  .t-body {
    position: relative;
    min-height: calc(100vh - 40rem);
    max-height: calc(100vh - 40rem);
    overflow: auto;
  }

  &.exeptions .t-row .t-row__item,
  &.exeptions .t-header .t-header__item {
    padding-right: 1rem;
    display: flex;
    .input-wrapper {
      margin-bottom: 0;
      .input {
        margin-bottom: 0;
      }
      input {
        width: 100%;
      }
    }
    .select-wrapper {
      width: 100%;
      input {
        width: 100%;
      }
    }
    // width: calc(100% / 6);

    &:nth-child(1) {
      width: 15%;
      position: relative;
    }
    &:nth-child(2) {
      width: 8%;
    }
    &:nth-child(3) {
      width: 12%;
    }
    &:nth-child(4) {
      width: 20%;
    }
    &:nth-child(5) {
      width: 15%;
    }
    &:nth-child(6) {
      width: 10%;
    }
    &:nth-child(7) {
      width: 15%;
    }
  }

  .t-row.even {
    background: $c-gray-95;
    border-radius: 1.25rem;
  }
  .t-label {
    border: 1px solid rgba(166, 172, 190, 0.4);
    box-sizing: border-box;
    border-radius: 1.25rem;
    padding: 1.5rem 1.5rem;
    margin-right: 0.7rem;
  }
}

.exeptions.table .t-header__item:nth-child(1),
.exeptions.table .t-row__item:nth-child(1) {
  width: 35rem;
  padding-right: 2rem;
}

.responsibles-dialog.dialog-wrapper {
  .dialog {
    display: flex;
    flex-direction: column;
    padding: 3rem 8rem;

    &.card {
      @extend .custom-scroll;
    }
    .card-line {
      width: 100%;
    }
    .card-actions {
      justify-content: center;
      margin-top: auto;
    }

    .checkbox-wrapper {
      margin-bottom: 1rem;
      // align-items: flex-start;
      height: auto;
      min-height: 2.5rem;
      .md-text {
        line-height: 1.75rem;
      }
    }
  }
}
