// Global Variables
@import './settings/global';

// Component Styles
@import './components.scss';
@import './parts/layout.scss';
@import './parts/analytics.scss';
@import './parts/filter.scss';
@import './parts/map.scss';
@import './parts/markers.scss';
@import './parts/selection.scss';
@import './parts/notifications.scss';
@import './parts/users.scss';

.flex-1 {
  flex-grow: 1;
}

.link {
  font-size: $fs-p3;
  line-height: $fs-p3-line;
  text-align: center;
  color: $c-gray-70;

  &.primary {
    color: $c-primary;
    font-style: normal;
    font-weight: 300;
  }
}
hr.line {
  border: 0;
  border-top: 1px solid $c-gray-95;
  margin: 3rem 0;
}

.card {
  height: 58.75rem;
  background: $c-white;
  box-shadow: 0px 0.5rem 0.5rem rgba(0, 0, 0, 0.25), $shadow;
  border-radius: 1.25rem;
  padding: 6.5rem 5% 3rem;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
  .card__title {
    color: $c-gray-30;
    font-weight: normal;
    text-align: center;
    margin-bottom: 6rem;
    margin-top: 0;
    @extend .h3-text;
  }
  .card-actions {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .link.primary {
      margin-left: 3.5rem;
    }
  }
  .card-bottom-link {
    margin-top: auto;
    text-align: center;
  }
  .card-line {
    border-bottom: 0.125rem solid $c-gray-80;
    margin: 2.5rem -3rem;
  }

  .error-message {
    text-align: center;
    @extend .sm-text;
    color: $c-primary;
    margin-bottom: auto;
  }
}

// Datepicker Styles
.calendar__btn {
  display: flex;
  flex-grow: 1;
  margin: 1.5rem 1.5rem;
  justify-content: center;
  align-items: center;
  width: 100%;
  color: $c-gray-60;
  cursor: pointer;
  @extend .sm-text;

  svg {
    width: 2.5rem;
    fill: $c-gray-70;
    height: 2.25rem;
    margin-right: 1.5rem;
  }
}
.calendar-wrapper {
  position: absolute;
  top: 17.3rem;
  left: 36rem;
  width: 37.5rem;
  background: $c-white;
  border: 1px solid #e8e8e8;
  border-radius: 10px;
  box-sizing: border-box;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.0479789);
  z-index: 1000;

  .Calendar {
    width: auto;
    background: none;
    z-index: 0;
    position: static;
    .calendar--selected_day {
      // background: $c-primary !important;
    }
  }
}
.DatePicker {
  width: 100%;

  .DatePicker__input {
    display: block;
    margin: auto;
    border: none;
    @extend .sm-text;
  }
}

.block-custom-scroll {
  margin-right: -1rem;
  padding-right: 1rem;
  // margin-top: 1rem;
  margin-bottom: 1rem;
  flex-grow: 1;
  position: relative;
  transition: all 0.3s ease-in;
  @extend .custom-scroll;
}

// Effect
.one-line-text {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.effect-scale-click-translate {
  transition: all 0.3s;
  &:hover {
    transform: translate(-50%, -50%) scale(1.05);
  }
  &:active {
    transform: translate(-50%, -50%) scale(1);
  }
}
.effect-scale-click {
  transition: all 0.3s;

  &:hover {
    transform: scale(1.05);
  }
  &:active {
    transform: scale(1);
  }
}
.custom-scroll {
  scrollbar-color: $c-gray-70 transparent;
  scrollbar-width: thin;
  overflow-y: auto;
  overflow-x: hidden;
}
::-webkit-scrollbar {
  width: 0.5rem;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  border-radius: 2rem;
  background-color: $c-gray-70;
  outline: none;
}

.loader-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;

  .loader,
  .loader:after {
    border-radius: 50%;
    width: 7.5rem;
    height: 7.5rem;
  }
  .loader {
    position: relative;
    z-index: 10;
    margin: 7.5rem auto;
    position: relative;
    border-top: 0.5rem solid $c-primary;
    border-right: 0.5rem solid $c-primary;
    border-bottom: 0.5rem solid $c-primary;
    border-left: 0.5rem solid #fff;
    transform: translateZ(0);
    animation: load8 1.5s infinite linear;
  }
  @-webkit-keyframes load8 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes load8 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
}

.grey-text {
  color: $c-gray-70;
}

span.active {
  cursor: pointer;
  color: $c-primary;
}

.d-flex {
  display: flex;
  &.align-center {
    align-items: center;
  }
}
.icon-wrapper {
  svg {
    width: 100%;
    height: 100%;
  }
}
