.navigation {
  display: flex;
  padding: 0 5rem;
  height: 8rem;
  background: $c-gray-20;
  box-sizing: border-box;
  align-items: center;
  position: relative;

  .navigation__logo {
    padding: 2rem 0;
    img {
      width: 16rem;
    }
  }
  .navigation-content__right {
    display: flex;
    align-items: center;
    margin-left: auto;
    padding: 2rem 0;
    .btn-primary {
      margin-left: 14px;
    }
  }
  .navigation-content {
    display: flex;
    width: 100%;
    height: 100%;
  }

  .navigation__link {
    padding: 0 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $c-gray-70;
    font-size: $fs-p2;
    line-height: $fs-p2-line;
    font-weight: 600;
    position: relative;
    text-decoration: none;
    cursor: pointer;
    svg {
      margin-right: 1.25rem;
      fill: $c-gray-70;
      height: 3rem;
    }
    &:hover {
      color: $c-white;
      svg {
        fill: $c-white;
      }
    }
    &:first-child {
      margin-left: 7rem;
    }

    &.active {
      color: $c-white;
      svg {
        fill: $c-primary;
      }

      &:before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        background: $c-primary-gradient;
        height: 0.75rem;
        border-radius: 10px 10px 0 0;
      }
    }
  }

  .navigation__username {
    color: $c-white;
    font-size: $fs-p3;
    line-height: $fs-p3-line;
    text-align: right;
  }
  .navigation__edit-profile {
    color: $c-gray-70;
    font-size: $fs-p4;
    line-height: $fs-p4-line;
    text-align: right;
    text-decoration: none;
  }
  .navigation__avatar {
    width: 5rem;
    height: 5rem;
    border-radius: 50%;
    padding: 0 3rem 0 1.5rem;
    background: url('../../assets/avatar.png') center center no-repeat;
    background-size: contain;
  }
  .navigation-btns {
    display: flex;
    align-items: center;
    border-left: 1px solid rgba(255, 255, 255, 0.4);
  }
  .navigation__btn {
    cursor: pointer;
    margin-left: 3rem;
    transition: all 0.3s;
    position: relative;

    &:hover {
      transform: scale(1.05);
    }
    &:active {
      transform: scale(1);
    }
    img {
      width: 3rem;
    }
    .notification__btn-label {
      position: absolute;
      top: -0.75rem;
      right: -0.75rem;
      background: $c-primary;
      color: $c-white;
      width: 1.75rem;
      height: 1.75rem;
      border-radius: 50%;
      @extend .caption-text, .flex-block-horizontal;
      justify-content: center;
    }
  }
}
.footer {
  display: flex;
  background: $c-gray-20;
  padding: 1.5rem 4rem;
  height: 7.5rem;
  align-items: center;
  box-sizing: border-box;
  &__logo {
    img {
      width: 11.75rem;
    }
  }

  .footer__link {
    color: $c-gray-70;
    font-size: $fs-p4;
    line-height: 2.25rem;
    padding: 0 1.625rem;
    text-decoration: none;
    font-weight: 600;

    &:first-child {
      margin-left: 6.25rem;
    }
  }
  .footer__copyright {
    color: $c-gray-70;
    font-size: $fs-p4;
    line-height: $fs-p4-line;
    margin-left: auto;
    text-align: right;
  }
}
.page-content {
  flex: 1 1;

  &.auth-page {
    background: url('../../assets/back.png');
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;

    .checkbox-wrapper {
      font-size: $fs-p4;
      line-height: $fs-p4-line;
      color: $c-gray-70;
      text-decoration-line: underline;
      margin-bottom: 1.5rem;
    }
  }

  &.main {
    // height: calc(100vh - 80px - 60px - 64px);
  }
}

.App {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  max-height: 100vh;
  height: 100vh;
}
