.marker--min {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%) scale(0.8);

  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  @extend .effect-scale-click-translate;

  &__icon {
    cursor: pointer;
    width: 8rem;
    height: 8rem;
    border-radius: 50%;
    margin: auto;
    position: relative;
    overflow: hidden;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 100%;
    }
  }
  &.in-list {
    .marker--min__icon {
      border: 0.25rem solid $c-primary;
    }
  }
  &.shop {
    .marker--min__icon img {
      width: 6rem;
      height: 6rem;
    }
  }
  &__label {
    background: $c-white;
    border: 1px solid $c-gray-80;
    border-radius: 3.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.5rem 1.25rem;
    margin-top: 1rem;
    box-sizing: border-box;
    color: $c-gray-30;
    white-space: nowrap;
    @extend .caption-semibold-text;

    svg {
      width: 1.5rem;
      height: 1.5rem;
      margin-right: 0.5rem;
    }
  }
}
.multi-mark .marker--min__label {
  margin-top: 0;
}
$zooms: 20;

@mixin zoom-x {
  @for $i from 1 through $zooms {
    .zoom-#{$i} .marker--min {
      transform: translate(-50%, -50%) scale($i / 20);
    }
  }
}

@include zoom-x;

.multimark {
  cursor: pointer;
  @extend .effect-scale-click;

  .market__count {
    @extend .caption-semibold-text;
    position: absolute;
    font-size: 15px;
    text-anchor: middle;
    fill: $c-gray-20;
  }
}

.marker-wrapper {
  position: relative;
  width: 0;

  .marker-card {
    top: -1.5rem;
    left: 50%;
    right: -50%;
    transform: translate(-50%, -50%);

    position: absolute;
    color: $c-gray-30;
    width: 24rem;
    background: $c-white;
    box-shadow: $shadow;
    border-radius: 1.25rem;
    padding: 1.5rem 0 1.5rem;

    .rating {
      .ml-auto {
        display: flex;
        align-items: center;

        svg {
          width: 1.5rem;
          height: 1.5rem;
          margin-right: 0.25rem;
        }
      }
    }
    .status {
      b.error {
        color: $c-primary;
      }
      b.warning {
        color: $c-yellow;
      }
      b.success {
        color: $c-green;
      }
    }
    b {
      @extend .caption-text;
    }
    .text-label {
      @extend .caption-text;
      color: $c-gray-60;
    }

    hr.line {
      margin: 1.75rem 0;
    }
    &__updated {
      position: absolute;
      left: 1rem;
      top: 1rem;
      color: #373737;
      @extend .caption-text;
      font-weight: bold;
      text-align: right;
    }
    &__avatar {
      cursor: pointer;
      width: 6rem;
      height: 6rem;
      min-height: 6rem;
      border-radius: 50%;
      display: block;
      margin: 0 auto;
      position: relative;
      overflow: hidden;
      box-shadow: 0 1rem 3.5rem 0 rgba(95, 100, 117, 0.5);

      img {
        position: absolute;
        width: 100%;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
      }

      &.market {
        .market__count {
          fill: $c-gray-20;
          text-anchor: middle;
          font-weight: normal;
          font-size: 13px;
          line-height: $fs-p4-line;
          font-weight: 600;
        }
      }
    }
    &.shop .marker-card__avatar {
      box-shadow: none;
    }
    &__name {
      text-align: center;
      font-weight: 600;
      margin-top: 1.5rem;
      color: $c-gray-60;
      @extend .sm-text;
    }
    &__fullname {
      text-align: center;
      font-weight: 600;
      margin-top: 1.5rem;
      color: $c-gray-30;
      @extend .sm-text;
    }
    &__number {
      margin: 0.5rem 0 0;
      text-align: center;
      a {
        text-decoration: none;
        color: $c-gray-60;
        @extend .sm-semibold-text;
      }
    }
    &__address {
      text-align: center;
      @extend .caption-text;
    }
    &__address-full {
      display: none;
    }
    .card-row {
      display: flex;
      align-items: center;
      margin-bottom: 1.25rem;
      padding: 0 1.25rem;
      flex-wrap: wrap;
    }
    .checkbox-wrapper {
      @extend .sm-text;
    }
    .more {
      display: block;
      margin: 2.5rem auto 0;
      @extend .sm-text;
    }

    &.full {
      width: 31.75rem;
      top: 17.3rem;
      left: -32rem;
      transform: translateX(0);
      min-height: calc(100vh - 16rem - 3rem);
      max-height: calc(100vh - 16rem - 3rem);
      box-sizing: border-box;
      z-index: 1002;
      transition: all 0.3s ease-in-out;
      padding-bottom: 0;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;

      hr.line {
        margin: 3rem 0;
      }
      .btn {
        @extend .sm-text;
      }
      .more {
        margin: 1.5rem auto 1.5rem;
      }
    }
    &.full.multimark {
      min-height: unset !important;
    }

    .card__bottom {
      display: flex;
      padding: 0 1.25rem;

      .btn {
        @extend .sm-text;
      }

      .plus {
        padding: 0;
        width: 5rem;
        height: 5rem;
        display: flex;
        margin-left: 1rem;
        justify-content: center;
        align-items: center;

        svg {
          width: 1.5rem;
          height: 1.5rem;
        }
      }
      .btn-secondary {
        padding: 1rem 1.5rem;
        margin: 3rem 0 3rem;
      }
      .btn.btn-primary {
        margin: 3rem 0 3rem 1.25rem;
      }
    }
  }
  &.show.left .full {
    right: unset;
    left: 1.5rem;
  }
  &.show.right {
    .full {
      left: unset;
      right: 1.5rem;
    }
    .calendar-wrapper {
      left: unset;
      right: 36rem;
    }
  }
}
.marker__close {
  position: absolute;
  top: 1rem;
  right: 1rem;
  background: none;
  padding: 0;
  z-index: 1000;
  border: 0;
  cursor: pointer;
  svg {
    width: 1rem;
    height: 1rem;
  }
}
.marker-wrapper .marker-card.full.multimark {
  width: 25rem;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  padding-top: 0;
}
svg.multimark {
  width: 10rem;
  height: 10rem;

  &.center {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-47.5%, -57.5%);
    &.shop {
      transform: translate(-52.5%, -70%);
    }
  }
}
.multi-mark {
  .profile-card {
    width: 25rem;
    padding: 0;

    &.open {
      .marker__close {
        display: block;
      }
    }
  }
  .filter-list__item {
    padding: 1.75rem 1.25rem;

    .btn-primary {
      margin: 1.5rem 0 0 1.5rem !important;
      width: 5rem;
      height: 5rem;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0;
    }
    .btn-secondary {
      margin-top: 1.5rem;
    }
  }
}

.multimarker--user {
  width: 30rem !important ;
  .filter-list {
    width: 30rem;
    .filter-list__item-top {
      margin-bottom: 0;
    }
  }
}

.history-table {
  @extend .caption-text;
  margin-top: 3rem;
  &-header {
    padding: 0 1.25rem;
    display: flex;
    justify-content: space-evenly;
    &__item {
      flex: 1 1;
      text-align: center;
      color: $c-gray-70;
      padding: 0.6rem 0;
      cursor: pointer;
      @extend .caption-semibold-text;

      &.active {
        color: $c-primary;
        position: relative;
        padding-bottom: 0.675rem;
        &:before {
          content: '';
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          background: $c-primary-gradient;
          height: 0.675rem;
          border-radius: 1.25rem 1.25rem 0 0;
        }
      }
    }

    &:nth-child(2) {
      background: $c-gray-95;
      border-top: 1px solid $c-gray-80;
    }
  }
  &-body {
    background: $c-gray-95;
    border-top: 1px solid $c-gray-80;
    border-bottom: 1px solid $c-gray-80;
    padding: 1.5rem 0.5rem 1.5rem 1.25rem;
  }
  &__inner {
    padding-right: 0.5rem;
    // max-height: 8rem;
    @extend .custom-scroll;
  }
  &__item {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;

    &:last-child {
      margin-bottom: 0;
    }
  }
  .info,
  .history {
    .card__bottom {
      margin-top: 1rem;
    }
  }
  .info {
    background: $c-gray-95;
    border-top: 1px solid $c-gray-80;
    border-bottom: 1px solid $c-gray-80;
    padding-top: 1.5rem;
    .card-row:last-child {
      .card-row__child {
        width: 100%;
      }
    }
  }

  &__no-item {
    text-align: center;
    font-weight: bold;
    @extend .caption-text;
  }
}
