// Buttons
button.btn {
  background: none;
  font-size: $fs-p2;
  line-height: $fs-p2-line;
  border-radius: 1.25rem;
  padding: 1rem 2.5rem;
  border: 0;
  cursor: pointer;
  transition: all 0.3s ease;
  border: 1px solid transparent;
  a {
    color: inherit;
    text-decoration: none;
  }

  &.btn-primary:not(disabled) {
    background: $c-primary-gradient;
    border: 0;
    color: $c-white;

    &:hover {
      background: $c-primary-hover;
    }
    &:active {
      background: $c-primary-active;
    }

    &:disabled {
      background: $c-gray-70;
    }
  }

  &.btn-secondary {
    background: $c-white;
    border-color: $c-primary;
    color: $c-gray-30;

    &.dark {
      background: transparent;
      color: $c-white;
    }
    &:hover {
      border-color: $c-primary-hover;
      color: $c-primary-hover;
    }
    &:active {
      border-color: $c-primary-active;
      color: $c-primary-active;
    }
  }

  &.btn-tetriary {
    background: $c-gray-95;
    color: $c-gray-60;

    &:hover {
      border-color: $c-gray-70;
    }
    &:active {
      border-color: $c-gray-80;
    }
  }
}
// Icon Button
button.btn-icon {
  border: none;
  position: relative;
  width: 5.75rem;
  height: 5.75rem;
  background: $c-white;
  box-shadow: $shadow;
  border-radius: 1.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  cursor: pointer;

  svg {
    fill: $c-gray-70;
    width: 3rem;
    cursor: pointer;
  }

  .btn-icon__label {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 2.5rem;
    height: 2rem;
    right: -0.75rem;
    top: -0.75rem;
    background: $c-primary-gradient;
    border-radius: 3.75rem;
    font-weight: 600;
    color: $c-white;

    @extend .caption-text;
  }
  &.active {
    svg {
      fill: $c-primary;
    }
  }
}
// Label Button
button.btn-label {
  width: 5rem;
  height: 5rem;
  border: 1px solid $c-gray-80;
  box-sizing: border-box;
  background: none;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;

  img {
    width: 1.25rem;
  }
  &:hover {
    border: 1px solid $c-white;
    background: $c-white;
  }
  &:active {
    border: 1px solid $c-primary;
    background: $c-white;
  }

  &.rotate {
    transform: rotate(180deg);
  }
}
// Add to List Button
button.btn-plus {
  display: flex;
  width: 5rem;
  min-width: 5rem;
  height: 5rem;
  box-sizing: border-box;
  padding: 0;
  justify-content: center;
  align-items: center;

  svg {
    width: 1.625rem;
    height: 1.625rem;
  }
}
// Custom Input
.input-wrapper {
  margin-bottom: 2.25rem;
  .input {
    display: flex;
    align-items: center;
    // position: relative;
    margin-bottom: 0.75rem;
    justify-content: flex-end;
    img {
      margin-right: 0.5rem;
      width: 3rem;
    }

    textarea,
    input {
      width: 100%;
      border: 1px solid $c-gray-70;
      box-sizing: border-box;
      border-radius: 1.25rem;
      padding: 0 1rem 0 1.5rem;
      outline: none;
      transition: all 0.3s;
      margin-bottom: 0;
      font-size: $fs-p3;
      line-height: $fs-p3-line;

      &::placeholder {
        color: $c-gray-70;
      }
      &:focus {
        border: 1px solid $c-gray-60;
        color: $c-gray-30;
      }
      &:read-only {
        color: $c-gray-70;
      }
    }
    textarea {
      padding: 1rem 1.5rem;
      resize: none;
    }
    input {
      width: 35.625rem;
      height: 5.25rem;
    }
  }
  &.error {
    input {
      border-color: $c-primary;
      color: $c-primary;
    }
    .input-error {
      color: $c-primary;
      padding-left: 3.5rem;
    }
  }
}
// Custom Checkbox
.checkbox-wrapper {
  display: flex;
  align-items: center;
  height: 2.5rem;
  position: relative;
  padding-left: 3.75rem;
  cursor: pointer;
  user-select: none;
  box-sizing: border-box;
  width: 100%;

  /* Hide the browser's default checkbox */
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 2.5rem;
    width: 2.5rem;
    box-sizing: border-box;
    border-radius: 0.625rem;
    border: 1px solid $c-gray-60;
  }
  /* On mouse-over, add a grey background color */
  &:hover input ~ .checkmark {
    border-color: $c-primary;
  }
  &:active input ~ .checkmark {
    border-width: 2px;
  }
  /* When the checkbox is checked */
  &.active .checkmark {
    border-color: $c-primary;
  }
  /* Create the checkmark/indicator (hidden when not checked) */
  &.active .checkmark:after {
    content: '';
    background: url('../assets/icons/checkbox.svg') center center no-repeat;
    background-size: contain;
    position: absolute;
    display: none;
  }
  /* Show the checkmark when checked */
  &.active .checkmark:after {
    display: block;
  }
  /* Style the checkmark/indicator */
  .checkmark:after {
    left: 0.625rem;
    top: 0.625rem;
    width: 1.125rem;
    height: 1.125rem;
  }
  &.error {
    animation: errorScale 1s ease-in;
    color: $c-primary !important;
  }
  @keyframes errorScale {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.05);
    }
    100% {
      transform: scale(1);
    }
  }
}
// Custom Datepicker
.datepicker-wrapper {
  // position: relative;
  border-radius: 1.25rem;
  border: 1px solid $c-gray-70;
  width: 15rem;
  height: 5.25rem;
  display: flex;
  align-items: center;

  &:nth-child(2) {
    margin-left: 1rem;
  }
  &.multi {
    width: 100%;
  }

  .datepicker-icon {
    // position: absolute;
    // top: 50%;
    // left: 0.5rem;
    width: 2.5rem;
    height: 2.5rem;
    margin-left: 0.5rem;
    // transform: translateY(-50%);
    // z-index: 100;
  }
  .DatePicker {
    z-index: auto;
    .Calendar {
      // position: relative;
      // z-index: 100;
    }
    // z-index: 1 !important;
    &__calendarContainer {
      // position: relative;
      z-index: 10000;
    }
    input {
      box-sizing: border-box;
      width: calc(100% - 0.5rem);
      margin: 0;
      padding: 0 0.4rem;
      outline: none;
      background: none;

      // &:focus {
      // }
    }
  }

  &.flat {
    width: 10rem;
    height: auto;
    margin-left: 0;
    border-radius: 0;
    border: none;

    .datepicker-icon {
      display: none;
    }
    input {
      padding: 0;
      margin: 0;
      width: 100%;
    }
  }
}
// Custom Select
.select-wrapper {
  position: relative;
  width: 30rem;
  box-sizing: border-box;
  font-size: $fs-p3;
  line-height: $fs-p3-line;
  color: $c-gray-30;
  font-weight: 400;
  background: none;
  height: 5.25rem;

  .select__inner {
    border: 1px solid $c-gray-70;
    border-radius: 1.25rem;
    box-sizing: border-box;
  }

  .select {
    height: 5.25rem;
    display: flex;
    align-items: center;
    // opacity: 0.4;
    padding: 0 1.5rem;
    cursor: pointer;
    input {
      border: none;
      outline: none;
    }
    span {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    svg {
      margin: 0.25rem 0 0 auto;
    }

    &.active {
      opacity: 1;
      color: $c-gray-30;

      svg {
        fill: $c-gray-30;
      }
    }
  }
  .select-list {
    display: none;
  }
  &.active {
    .select__inner {
      background: $c-white;
      position: relative;
      z-index: 100;
    }
    .select {
      opacity: 1;
      color: $c-gray-30;

      svg {
        fill: $c-gray-30;
      }
    }

    .select-list {
      display: block;
      max-height: 21.25rem;
      overflow-y: scroll;
      z-index: 100;

      .select-list__item {
        display: flex;
        color: $c-gray-70;
        align-items: center;
        padding: 0 1.5rem;
        min-height: 5.25rem;
        border-top: 1px solid $c-gray-70;
        cursor: pointer;
        position: relative;

        .checkbox-wrapper {
          min-height: 2.5rem;
          height: auto;
          align-items: flex-start;
          .checkmark {
            top: 50%;
            transform: translateY(-50%);
          }
        }

        .select-list__item--sub {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          right: -5rem;
        }

        &:hover {
          color: $c-gray-30;
        }
      }
    }
  }
}
// Address AutoComplete
.address-input-wrapper {
  &.search-input {
    display: block;
  }
  input {
    width: 100%;
    border: none;
    outline: none;
  }
  .select-list {
    display: block;
    z-index: 100;

    .select-list__item {
      display: flex;
      color: $c-gray-70;
      align-items: center;
      padding: 0 1.5rem;
      min-height: 5.25rem;
      border-top: 1px solid $c-gray-70;
      cursor: pointer;

      &:hover {
        color: $c-gray-30;
      }
    }
  }
  .select {
    position: relative;
    margin: 0 1.5rem;
    padding: 0;
  }
  .search-input__logo {
    height: 3rem;
    cursor: pointer;

    img {
      width: 3rem;
    }
  }
}
// Default Card
.card-default {
  background: $c-white;
  box-shadow: $shadow;
  border-radius: 1.25rem;
}
// Custom Switch
.switch {
  display: flex;
  padding: 0.5rem;
  border: 1px solid $c-gray-70;
  font-size: $fs-p3;
  line-height: $fs-p3-line;
  color: $c-gray-70;
  border-radius: 1.25rem;
  box-sizing: border-box;

  .switch__item {
    border-radius: 1rem;
    padding: 1rem 3rem;
    transition: all 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &.active {
      background: $c-primary-gradient;
      color: $c-white;
    }
  }
}
// Loading Line
.loading-wrapper {
  position: absolute;
  display: flex;
  top: 0;
  left: 0;
  z-index: 110;
  width: 100%;
  height: 0.5rem;

  .loading-indicator {
    width: 0;
    background: $c-primary;
  }

  &.isBackground {
    height: 100%;
    align-items: flex-start;
    background: rgba(255, 255, 255, 0.5);
    .loading-indicator {
      height: 0.5rem;
    }
  }
}

.user-comment-input {
  textarea {
    resize: none;
    width: 100%;
    border: 1px solid $c-gray-70;
    box-sizing: border-box;
    border-radius: 1.25rem;
    padding: 0.5rem 1rem;
    outline: none;
    transition: all 0.3s;
    margin-top: 1rem;
    margin-bottom: 0;
    font-size: $fs-p3;
    line-height: $fs-p3-line;

    &::placeholder {
      color: $c-gray-70;
    }
    &:focus {
      border: 1px solid $c-gray-60;
      color: $c-gray-30;
    }
  }
}
