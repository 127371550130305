@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;600;700&display=swap');
@import 'normalize.css';

// COLORS
$c-gray-20: #151515;
$c-gray-30: #373737;
$c-gray-60: #5f6475;
$c-gray-70: #a6acbe;
$c-gray-80: #e4e6ea;
$c-gray-95: #f8f8f8;
$c-gray-98: #fbfcff;
$c-white: #ffffff;

$c-red: #f44242;
$c-yellow: #ffb800;
$c-green: #48d53b;

$c-primary: #e5264c;
$c-primary-hover: linear-gradient(71.22deg, #fd204c 0, #fd204c 100%);
$c-primary-active: linear-gradient(71.22deg, #f70e3d 0, #f70e3d 100%);
$c-primary-visited: #a94853;
$c-primary-gradient: linear-gradient(71.22deg, #af2d38 7.74%, #e5264c 90.99%);

$shadow: 0px 1rem 2rem rgba(166, 172, 190, 0.4);
// FONT SIZES
html {
  // 1440 px -> 8px
  font-size: 0.556vw;
  font-family: 'Montserrat', sans-serif;
  max-width: 100vw;
  overflow: hidden;
  box-sizing: border-box;
}

$fs-h1: 5rem;
$fs-h1-line: 139%;

$fs-h2: 3.75rem;
$fs-h2-line: 133%;

$fs-h3: 3.125rem;
$fs-h3-line: 120%;

$fs-h4: 2.5rem;
$fs-h4-line: 125%;

$fs-p1: 2.25rem;
$fs-p1-line: 167%;

$fs-p2: 2rem;
$fs-p2-line: 156%;

$fs-p3: 1.75rem;
$fs-p3-line: 143%;

$fs-p4: 1.5rem;
$fs-p4-line: 133%;

.h1-text {
  font-size: $fs-h1;
  line-height: $fs-h1-line;
}
.h2-text {
  font-size: $fs-h2;
  line-height: $fs-h2-line;
}
.h3-text {
  font-size: $fs-h3;
  line-height: $fs-h3-line;
}
.h4-text {
  font-size: $fs-h4;
  line-height: $fs-h4-line;
}
.h4-italic-text {
  font-size: $fs-h4;
  line-height: $fs-h4-line;
  font-style: italic;
}
.h4-semibold-text {
  font-size: $fs-h4;
  line-height: $fs-h4-line;
  font-weight: 600;
}

.lg-text {
  font-size: $fs-p1;
  line-height: $fs-p1-line;
}
.lg-semibold-text {
  font-size: $fs-p1;
  line-height: $fs-p1-line;
  font-weight: 600;
}
.lg-link-text {
  font-size: $fs-p1;
  line-height: $fs-p1-line;
  text-decoration: underline;
}

.md-semibold-text {
  font-size: $fs-p2;
  line-height: $fs-p2-line;
  font-weight: 600;
}
.md-text {
  font-size: $fs-p2;
  line-height: $fs-p2-line;
}
.md-link-text {
  font-size: $fs-p2;
  line-height: $fs-p2-line;
  text-decoration: underline;
}

.sm-semibold-text {
  font-size: $fs-p3;
  line-height: $fs-p3-line;
  font-weight: 600;
}
.sm-text {
  font-size: $fs-p3;
  line-height: $fs-p3-line;
}
.sm-link-text {
  font-size: $fs-p3;
  line-height: $fs-p3-line;
  text-decoration: underline;
}

.caption-semibold-text {
  font-size: $fs-p4;
  line-height: $fs-p4-line;
  font-weight: 600;
}
.caption-text {
  font-size: $fs-p4;
  line-height: $fs-p4-line;
}
.caption-link-text {
  font-size: $fs-p4;
  line-height: $fs-p4-line;
  text-decoration: underline;
}

button {
  outline: none;
  user-select: none;
}
.flex-block-horizontal {
  display: flex;
  align-items: center;
}
.ml-auto {
  margin-left: auto;
}
img {
  max-width: 100%;
}
b,
strong {
  font-weight: 600;
}
