.users-page {
  padding: 7.25rem 3rem 3rem;
  flex: 1;
  display: flex;

  .search-input {
    border: 1px solid $c-gray-70;
    border-radius: 1.25rem;
    padding: 1.5rem 2rem 1.5rem 6rem;
    max-width: 37.5rem;
  }

  .card {
    flex: 1;
    padding: 2.5rem 3rem;
    height: auto !important;
    .card-header {
      display: flex;
      align-items: center;
      padding-right: 7.25rem;
    }
    .card-line {
      border-bottom: 0.125rem solid $c-gray-80;
      margin: 2.5rem -3rem;
    }
  }

  .table {
    .t-row__item,
    .t-header__item {
      &:nth-child(1) {
        width: 10%;
      }
      &:nth-child(2) {
        width: 10%;
      }
      &:nth-child(3) {
        width: 10%;
      }
      &:nth-child(4) {
        width: 10%;
      }
      &:nth-child(5) {
        width: 20%;
      }
      &:nth-child(6) {
        width: 15%;
      }
      &:nth-child(7) {
        width: 25%;
        display: flex;
        justify-content: space-between;
      }
    }
    .t-body {
      overflow-y: auto;
      max-height: calc(100vh - 40rem);
    }
  }
}
.dialog-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;

  .dialog {
    background: $c-white;
    box-shadow: 0px 0.5rem 0.5rem rgba(0, 0, 0, 0.25), $shadow;
    border-radius: 1.25rem;
    padding: 3rem 5% 3rem;
    width: 50rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    .loading-wrapper {
      border-radius: 1.25rem;
      overflow: hidden;
    }

    .card-actions {
      width: 100%;
    }
    .select-wrapper {
      width: 35.625rem;
      margin-bottom: 5rem;
    }
  }
}
